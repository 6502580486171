<template>
  <div class="relative bg-right bg-no-repeat bg-cover" style="background-image: url('/images/bg-banner.jpg')">
    <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-opacity-75 bg-brown-2"></div>
    <!-- navigation  -->
    <div class="fixed top-0 left-0 z-20 w-full py-2 transition duration-200 ease-linear bg-transparent"
      :class="fixtop ? '!bg-gray-900 shadow-md z-10' : 'bg-transparent'">
      <div class="container">
        <div class="flex items-center justify-between w-full">
          <div class="w-1/2 lg:w-1/3">
            <img src="/images/main-logo-white.png" alt="" class="w-32" />
          </div>
          <div class="w-1/12 lg:hidden">
            <button @click="showMenu()" class="inline-block sidebar-toggle-btn">
              <svg class="w-8 h-8 text-white fill-current" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29 6H3a1 1 0 0 0 0 2h26a1 1 0 0 0 0-2ZM3 17h13a1 1 0 0 0 0-2H3a1 1 0 0 0 0 2Zm22 7H3a1 1 0 0 0 0 2h22a1 1 0 0 0 0-2Z"
                  data-name="menu" />
              </svg>
            </button>
          </div>
          <div class="hidden lg:block">
            <ul class="inline-flex items-center font-medium text-gray-300">
              <li class="mx-2 transition duration-300 hover:text-white">
                <a href="#" v-scroll-to="{ el: '#product', duration: 1500 }">What We Do</a>
              </li>
              <li class="mx-2 transition duration-300 hover:text-white">
                <a href="#" v-scroll-to="{ el: '#showroom', duration: 1500 }">Our Showroom</a>
              </li>
              <li class="mx-2 transition duration-300 hover:text-white">
                <a href="#" v-scroll-to="{ el: '#packages', duration: 1500 }">Our Packages</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- dropdown -->
      <div class="relative z-50 mt-3 transition duration-300 ease-in-out lg:hidden"
        :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'">
        <ul class="text-base text-center text-gray-100 bg-brown-2">
          <li @click="closeNav" class="py-3 hover:bg-black hover:bg-opacity-60 hover:text-white">
            <a href="#" v-scroll-to="{ el: '#product', duration: 1500 }">What We Do</a>
          </li>
          <li @click="closeNav" class="py-3 hover:bg-black hover:bg-opacity-60 hover:text-white">
            <a href="#" v-scroll-to="{ el: '#showroom', duration: 1500 }">Our Showroom</a>
          </li>
          <li @click="closeNav" class="py-3 hover:bg-black hover:bg-opacity-60 hover:text-white">
            <a href="#" v-scroll-to="{ el: '#packages', duration: 1500 }">Our Packages</a>
          </li>
        </ul>
      </div>
      <!-- END dropdown -->
    </div>

    <div class="container">
      <div class="relative z-10 py-60">
        <div class="text-center capitalize">
          <p class="text-xl font-bold text-gray-300 lg:text-2xl">
            Avenue Design Holding Sdn Bhd
          </p>
          <div class="inline-flex items-center justify-center pt-2">
            <div class="bg-white h-0.5 w-10"></div>
            <h1 class="px-2 text-4xl text-white md:px-5 md:text-5xl">
              You Dream. <br class="md:hidden" />
              We Build.
            </h1>
            <div class="bg-white h-0.5 w-10"></div>
          </div>

          <h2 class="pt-4 font-light leading-tight text-white capitalize lg:text-2xl">
            For Your Customisable Kitchen & Wardrobes | Perfected to Suit Your
            Space
          </h2>
          <h5 class="pt-5 text-2xl text-white capitalize lg:text-3xl">
            We Provide Free Professional On-Site Consultation
          </h5>
        </div>
        <div class="text-center text-white">
          <div class="pt-5">
            <!-- <p class="font-bold">Message Us on WhatsApp</p> -->
            <p class="font-medium">
              <i class="pr-2 text-lg fa-brands fa-whatsapp"></i>
              <a href="https://wa.me/60123948112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet"
                class="hover:text-yellow-1">012-394 8112</a>
              |
              <a href="https://wa.me/60163178112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet"
                class="hover:text-yellow-1">016-317 8112</a>
            </p>
          </div>

          <div class="pt-2 font-medium">
            <!-- <p class="font-bold">Call Us</p> -->
            <p class="font-medium">
              <i class="pr-2 text-lg fa-solid fa-phone"></i>
              <a href="tel:+60123948112" class="hover:text-yellow-1">012-394 8112</a>
              |
              <a href="tel:+60163178112 " class="hover:text-yellow-1">016-317 8112</a>
            </p>
          </div>
        </div>
        <!-- <div class="pt-4 space-y-1 md:flex md:justify-center md:space-y-0 md:space-x-1">
          <div
            class="py-1 mx-auto text-center text-white transition duration-300 ease-linear transform rounded-md md:mx-0 w-44 bg-green-1 hover:scale-110"
          >
            <a href="https://wa.me/60163178112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet">TALK TO US</a>
          </div>
          <div
            class="py-1 mx-auto text-center text-white uppercase transition duration-300 ease-linear transform rounded-md md:mx-0 w-44 bg-green-1 hover:scale-110"
          >
            <a href="tel:+60123948112">Get A Free Quote</a>
          </div>
        </div> -->

        <div class="flex flex-wrap justify-center pt-3">
          <div class="absolute">
            <div class="flex">
              <img src="/images/logo-1.png" alt="" class="w-28 h-28 lg:w-36 lg:h-36 xl:w-40 xl:h-40">
              <img src="/images/logo-2.png" alt="" class="w-28 h-28 lg:w-36 lg:h-36 xl:w-40 xl:h-40">
              <img src="/images/logo-3.png" alt="" class="w-28 h-328 lg:w-36 lg:h-36 xl:w-40 xl:h-40">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixtop: false,
      showMobileMenu: false,
    };
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },

    closeNav() {
      this.showMobileMenu = !this.showMobileMenu;
      document.body.classList.remove(true);
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style>
.nav-menu {
  padding-top: 10px;
  position: absolute;
  width: 100%;
}

.open-menu {
  opacity: 1;
  height: 150px;
}

.closed-menu {
  opacity: 0;
  height: 0;
  padding: 0;
}
</style>
